<template>
    <div>
        <v-container style="margin-top: 100px; margin-bottom: 30px; text-align: center;">
            <center>
                <v-btn rounded elevation="0" color="black" dark class="mb-3 hidden-sm-and-down" small
                    @click="goToSubmit()">Promote your GPTs with more than <b>+</b>7.000 people<v-icon right
                        x-small>mdi-open-in-new</v-icon></v-btn>
                <v-btn rounded elevation="0" color="black" dark class="mb-3 hidden-md-and-up" x-small
                    @click="goToSubmit()">Promote your GPTs with more than <b>+</b>7.000 people<v-icon right
                        x-small>mdi-open-in-new</v-icon></v-btn>

                <br>
                <p class="intro-text-head-desc mt-5 hidden-sm-and-down">Get and use a <span
                        style="background-color: #7579e7; color: white; border-radius: 15px;"><span
                            class="mr-2 ml-2">random</span></span> GPT.</p>
                <p class="intro-text-head-desc-mb mt-5 hidden-md-and-up">Get and use a <span
                        style="background-color: #7579e7; color: white; border-radius: 15px;"><span
                            class="mr-2 ml-2">random</span></span> GPT.</p>

                <p><b>Subscribe</b> to recieve the best GPTs weekly.</p>
                <div style="margin-top: 50px; margin-bottom: 10px;">
                    <v-btn large @click="randomGPT()" rounded color="black" class="white--text pulse"><v-icon
                            left>mdi-creation</v-icon>Random GPT</v-btn>
                </div>
                <div class="spinner" style="margin-top: 110px !important;" v-if="showLoader == true">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <br>
                <v-card v-if="showGPT == true" class="mx-auto custom-card" width="400" height="fit-content" outlined
                    elevation="1" style="border-radius: 15px;" @click="goToGPT(GPTrandom.url_gpt)">
                    <v-avatar tile size="75" color="transparent" class="mt-5">
                        <v-img :src="GPTrandom.url_logo" style="border-radius: 100%;"></v-img>
                    </v-avatar>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <v-list-item-title style="font-size: 1.2rem; font-weight: 600;" class="mt-1">
                                <v-icon color="#26d364" small v-if="GPTrandom.new == true">mdi-new-box</v-icon> <v-icon
                                    color="#26d364" v-if="GPTrandom.featured == true">mdi-menu-up</v-icon> <v-icon
                                    color="#7579e7" small v-if="GPTrandom.featured == true">mdi-check-decagram</v-icon> {{
                                        GPTrandom.name_gpt }}
                            </v-list-item-title>
                            <p style="font-size: 0.8rem; color: rgb(27, 27, 27);" class="mt-2">{{
                                GPTrandom.desc_gpt }}</p>
                        </v-list-item-content>
                    </v-list-item>
                    <v-btn color="#10a37f" class="mb-4 white--text">Use in <b>ChatGPT</b><v-icon right
                            small>mdi-open-in-new</v-icon></v-btn>
                </v-card>
            </center>
        </v-container>
        <v-dialog v-model="subscribePopUp" width="fit-content">
            <v-card width="fit-content" style="border-radius: 15px;" class="hidden-sm-and-down">
                <iframe class="ml-3 mb-3 mr-3" src="https://tally.so/embed/3X4x1d?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" style="border:0px #ffffff none;" name="myiFrame" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="400px" width="500px" allowfullscreen></iframe>
            </v-card>
            <v-card width="fit-content" style="border-radius: 15px;" class="hidden-md-and-up">
                <iframe class="ma-3" src="https://tally.so/embed/3X4x1d?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" style="border:0px #ffffff none;" name="myiFrame" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="400px" width="300px" allowfullscreen></iframe>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

// @ is an alias to /src  
import axiosGPTs from "@/logic/ProGPTs/users/axios"
export default {
    name: 'HomeView',
    components: {
    },
    async mounted() {

        const data_all_gpts = await axiosGPTs.get_all_gpts()
        console.log(data_all_gpts.data.data)

        if (data_all_gpts.data.code == 'success') {
            //cuando el post sea exitoso
            this.gpts = data_all_gpts.data.data.filter(gpt => gpt.name_gpt !== '') //Filtra para que solo salgan los gpts completos

            // this.gpts = data_all_gpts.data.data
            console.log("Data success!", this.gpts)
        } else {
            console.log("Data Error")
        }
    },
    data() {
        return {
            searchTerm: '',
            gpts: [],
            filteredGpts: [],
            GPTrandom: {},
            showLoader: false,
            showGPT: false,
            clickCount: 0,
            subscribePopUpNum: 5,
            subscribePopUp: false
        };
    },
    methods: {

        goToSubmit() {
            window.location.href = "https://chatpro.pro/gpts"; //redirecciona a chatpro register
        },
        goToGPT(url) {
            window.open(url, '_blank');
        },
        searchGpts() {
            // Filtra los gpts según el término de búsqueda
            this.filteredGpts = this.gpts.filter(gpt => {
                // Puedes ajustar las propiedades que deseas incluir en la búsqueda
                return (
                    gpt.name_gpt.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    gpt.desc_gpt.toLowerCase().includes(this.searchTerm.toLowerCase())
                );
            });
        },
        truncateText(text, maxChars) {
            // console.log(text)
            if (text == null) {
                return null
            }
            else {
                return text.length > maxChars ? text.slice(0, maxChars) + "..." : text;
            }
        },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async randomGPT() {
            this.showLoader = true
            this.GPTrandom = null
            this.showGPT = false
            this.clickCount = this.clickCount + 1
            console.log(this.clickCount)
            if (this.clickCount == this.subscribePopUpNum) {
                this.subscribePopUp = true
            }
            if (this.clickCount == this.subscribePopUpNum + 5) {
                this.subscribePopUp = true
            }
            // Generar un número decimal aleatorio entre 0 (inclusive) y 1 (exclusivo)
            var indiceAleatorio = Math.floor(Math.random() * this.gpts.length);
            await this.delay(700)
            // Devolver el elemento correspondiente al índice aleatorio
            this.GPTrandom = this.gpts[indiceAleatorio];
            this.showLoader = false
            this.showGPT = true
        }


    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head-desc {
    color: rgb(0, 0, 0);
    font-size: 2.5rem;
    font-weight: 550;
}

.intro-text-head-desc-mb {
    color: rgb(0, 0, 0);
    font-size: 2rem;
    font-weight: 550;
}


.custom-card:hover {
    /* Aplica el estilo al pasar el mouse por encima */
    background-color: #e0e0e0;
    /* Cambia el color de fondo al pasar el mouse por encima */
}


/* Spiner */

/* HTML: <div class="loader"></div> */
.spinner {
    width: 11.2px;
    height: 11.2px;
    animation: spinner-o824ag 1s infinite linear;
}

.spinner div {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #7579e7;
    border-radius: 50%;
    animation: spinner-vse6n7 1.25s infinite ease;
}

.spinner div:nth-child(1) {
    --rotation: 90;
}

.spinner div:nth-child(2) {
    --rotation: 180;
}

.spinner div:nth-child(3) {
    --rotation: 270;
}

.spinner div:nth-child(4) {
    --rotation: 360;
}

@keyframes spinner-vse6n7 {

    0%,
    100% {
        transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
    }

    50% {
        transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
    }
}

@keyframes spinner-o824ag {
    to {
        transform: rotate(360deg);
    }
}

/**button */
.pulse {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}
</style>

