import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
// import VueScrollTo from 'vue-scrollto'
import VueSimpleAlert from "vue-simple-alert";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import './styles.scss'


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCMUj9ZNnVfpegebyv_1hizWFjCJzG4BJA",
  authDomain: "pro-gpts.firebaseapp.com",
  projectId: "pro-gpts",
  messagingSenderId: "346228979992",
  appId: "1:346228979992:web:b92bbd0cc8f7f0255a466d",
  measurementId: "G-DTZ6TLX1M8"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

Vue.config.productionTip = false
Vue.use(VueSimpleAlert);


new Vue({
  vuetify,
  router,
  render: h => h(App)
  
}).$mount('#app')




