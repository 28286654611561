import Vue from "vue";
import Router from "vue-router";

// Importar las vistas
import home from "../views/home.vue";
import random from "../views/random.vue";





Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: home,
    },
    {
      path: "/random",
      name: "Random",
      component: random,
    }
  ],
});

export default router;
