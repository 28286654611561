<template>
    <div>
        <v-container style="margin-top: 100px; margin-bottom: 30px; text-align: center;">
            <center>
                <v-btn rounded elevation="0" color="black" dark class="mb-3 hidden-sm-and-down" small
                    @click="goToSubmit()">Promote your GPTs with more than <b>+</b>7.000 people<v-icon right
                        x-small>mdi-open-in-new</v-icon></v-btn>
                <v-btn rounded elevation="0" color="black" dark class="mb-3 hidden-md-and-up" x-small
                    @click="goToSubmit()">Promote your GPTs with more than <b>+</b>7.000 people<v-icon right
                        x-small>mdi-open-in-new</v-icon></v-btn>
                <br>
                <a href="https://www.producthunt.com/posts/progpts?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-progpts"
                    target="_blank"><img class="mt-2"
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=425142&theme=light"
                        alt="ProGPTs - Promote&#0032;and&#0032;make&#0032;money&#0032;with&#0032;your&#0032;OpenAI&#0032;GPTs&#0046; | Product Hunt"
                        style="width: 200px; height: 43.2px;" width="200" height="43.2" /></a>
                <p class="intro-text-head-desc mt-5">An exclusive list of the best GPTs.</p>
                <p>Find, Share and <b><u>promote</u></b> your GPTs.</p>

                <v-btn rounded elevation="0" color="black" dark class="ma-1" @click="goToSubmit()">Submit<v-icon
                        right>mdi-plus</v-icon></v-btn>
                <a
                    href="#tally-open=3X4x1d&tally-layout=modal&tally-width=500&tally-emoji-animation=none&tally-auto-close=4000"><v-btn
                        rounded elevation="0" color="#e0e0e0" ligth class="ma-1">Subscribe<v-icon
                            right>mdi-email</v-icon></v-btn></a>
            </center>
        </v-container>

        <v-container fluid>
            <v-text-field v-model="searchTerm" @input="searchGpts" style="max-width: 900px;" :label="'Search...'" dense
                prepend-inner-icon="mdi-creation" color="black" rounded class="mx-auto mb-2" filled></v-text-field>
            <v-row justify="center" style="max-width: 1500px;" class="mx-auto" v-if="searchTerm === ''">
                <v-col v-for="(gpt, index) in gpts" :key="index" cols="12" sm="6" md="4" lg="3">
                    <v-card class="mx-auto custom-card" width="350" height="110" outlined elevation="1"
                        style="border-radius: 15px;" @click="goToGPT(gpt.url_gpt)">
                        <v-list-item three-line>
                            <v-list-item-avatar tile size="60" color="transparent">
                                <v-img :src="gpt.url_logo" style="border-radius: 10px;"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title style="font-size: medium; font-weight: 600;" class="mt-1">
                                    <v-icon color="#26d364" small v-if="gpt.new == true">mdi-new-box</v-icon> <v-icon
                                        color="#26d364" v-if="gpt.featured == true">mdi-menu-up</v-icon><v-icon
                                        color="#7579e7" small v-if="gpt.featured == true">mdi-check-decagram</v-icon> {{
                                            gpt.name_gpt }}
                                </v-list-item-title>
                                <p style="font-size: 0.7rem; color: rgb(27, 27, 27);" class="mt-2">{{
                                    truncateText(gpt.desc_gpt, 105) }}</p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" style="max-width: 1500px;" class="mx-auto" v-if="searchTerm != ''">
                <v-col v-for="(gpt, index) in filteredGpts" :key="index" cols="12" sm="6" md="4" lg="3">
                    <v-card class="mx-auto custom-card" width="350" height="110" outlined elevation="1"
                        style="border-radius: 15px;" @click="goToGPT(gpt.url_gpt)">
                        <v-list-item three-line>
                            <v-list-item-avatar tile size="60" color="transparent">
                                <v-img :src="gpt.url_logo" style="border-radius: 10px;"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title style="font-size: medium; font-weight: 600;" class="mt-1">
                                    <v-icon color="#26d364" small v-if="gpt.new == true">mdi-new-box</v-icon> <v-icon
                                        color="#26d364" v-if="gpt.featured == true">mdi-menu-up</v-icon> <v-icon
                                        color="#7579e7" small v-if="gpt.featured == true">mdi-check-decagram</v-icon> {{
                                            gpt.name_gpt }}
                                </v-list-item-title>
                                <p style="font-size: 0.7rem; color: rgb(27, 27, 27);" class="mt-2">{{
                                    truncateText(gpt.desc_gpt, 105) }}</p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

// @ is an alias to /src  
import axiosGPTs from "@/logic/ProGPTs/users/axios"
export default {
    name: 'HomeView',
    components: {
    },
    async mounted() {

        const data_all_gpts = await axiosGPTs.get_all_gpts()
        // console.log("Todos", data_all_gpts.data.data)

        if (data_all_gpts.data.code == 'success') {

            //cuando el post sea exitoso
            const completeGpts = data_all_gpts.data.data.filter(gpt => gpt.name_gpt !== '') //Filtra para que solo salgan los gpts completos

            // console.log("Completos", completeGpts)

            // Paso 1: Identificar objetos duplicados
            const urlSet = new Set(); // Usamos un Set para realizar un seguimiento de URLs únicas
            const duplicatesToRemove = new Set();

            completeGpts.forEach((obj) => {
                if (urlSet.has(obj.url_gpt)) {
                    duplicatesToRemove.add(obj.url_gpt);
                } else {
                    urlSet.add(obj.url_gpt);
                }
            });

            // Paso 2: Eliminar objetos duplicados
            this.gpts = completeGpts.filter((obj) => !duplicatesToRemove.has(obj.url_gpt));

            // this.gpts = data_all_gpts.data.data
            // console.log("Data success!", this.gpts)
        } else {
            console.log("Data Error")
        }
    },
    data() {
        return {
            searchTerm: '',
            gpts: '',
            filteredGpts: []
        };
    },
    methods: {

        goToSubmit() {
            window.location.href = "https://chatpro.pro/gpts"; //redirecciona a chatpro register
        },
        goToGPT(url) {
            window.open(url, '_blank');
        },
        searchGpts() {
            // Filtra los gpts según el término de búsqueda
            this.filteredGpts = this.gpts.filter(gpt => {
                return (
                    gpt.name_gpt.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    gpt.desc_gpt.toLowerCase().includes(this.searchTerm.toLowerCase())
                );
            });
        },
        truncateText(text, maxChars) {
            // console.log(text)
            return text.length > maxChars ? text.slice(0, maxChars) + "..." : text;
        },


    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head-desc {
    color: rgb(0, 0, 0);
    font-size: 2.5rem;
    font-weight: 550;
}

.custom-card:hover {
    /* Aplica el estilo al pasar el mouse por encima */
    background-color: #e0e0e0;
    /* Cambia el color de fondo al pasar el mouse por encima */
}
</style>

