<template>
  <div>
    <v-app>
      <Header />
      <v-main style="padding: 0px 0px 0px; background-color: rgb(255, 255, 255);" class="mb-15">
        <Intro />
      </v-main>
      <Footer />

    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'
import Intro from '@/components/components_landing_home/Intro.vue'


export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    Intro,
    
  },
}
</script>



