<template>
  <v-footer padless width="100hx">
    <v-card flat tile width="100%" style="background-color:#ffffff;" class="white--text text-center">
      <v-img class="mx-auto" src='https://d153chakadu90d.cloudfront.net/web/pwrd_chatpro.webp' max-height="150"
        max-width="150" contain alt="Custom ChatGPT Assistants in a few clicks"></v-img>
      <p class="black--text">Embed ChatGPT in your Website</p>
      <v-card-text >
        <v-row style="width: 35%;" class="mx-auto">
          <v-col >
            <a href="https://app.pill.com.co/chatpro" style="text-decoration: none;">
              <p class="black--text"><u>Careers</u></p>
            </a>
          </v-col>
          <v-col >
            <a href="https://chatpro.pro/terms" style="text-decoration: none;">
              <p class="black--text"><u>Terms & Conditions</u></p>
            </a>
          </v-col>
          <v-col >
            <a href="https://chatpro.pro/privacy" style="text-decoration: none;">
              <p class="black--text"><u>Privacy Policy</u></p>
            </a>
          </v-col>
          <v-col >
            <a href="mailto:chatpro.contact@gmail.com" style="text-decoration: none;">
              <p class="black--text"><u>Contact</u></p>
            </a>
          </v-col>

        </v-row>

        <br>
        <a target="_blank" v-bind:href="social.url" v-for="(social, index) in socials" style="text-decoration: none;" :key="index">
          <v-btn class="mx-4" icon color="black">
            <v-icon size="30px">{{ social.icon }}</v-icon>
          </v-btn>
        </a>
      </v-card-text>
      <v-card-text class="pt-0 text-left text-md-center">
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text style="background-color:#000000;" class="white--text">
        © {{ new Date().getFullYear() }} ProGPTs - All rights reserved.
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer-",

  data: () => ({
    panel: [],
    socials: [
      { name: "linkedin", icon: "mdi-linkedin", url: "https://www.linkedin.com/company/chatproai/" },
      { name: "instagram", icon: "mdi-instagram", url: "https://www.instagram.com/chatpro.ai/" }
    ],

  }),

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      if (window.innerWidth >= 960) {
        this.panel = [...Array(4).keys()].map((k, i) => i);
      } else {
        this.panel = [];
      }
    }
  }
}

</script>

<style scoped></style>