<template>
    
    <div>
        <!--Medida más pequeña 360x740-->
        <v-app-bar class="top hidden-md-and-up mt-3 ml-1 mr-1" app style="background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(13px);
    border: 1px solid rgba(173, 173, 173, 0.05); border-radius: 15px; " elevation="0" height="75">
            <v-app-bar-nav-icon light @click.stop="dialogMenu = true"></v-app-bar-nav-icon>

            <v-img src="https://d153chakadu90d.cloudfront.net/web/ProGPTs_logo.webp" class="ml-2"
                @click="redirectToHome()" max-height="120" max-width="120" alt="ChatGPT ChatPro Logo AI"></v-img>

            <v-spacer></v-spacer>

            <v-btn @click="goToSubmit()" rounded color="black" dark class="mr-2">
                Submit GPT<v-icon right>mdi-plus</v-icon>
            </v-btn>
        </v-app-bar>

        <!--PC-->
        <v-app-bar class="hidden-sm-and-down mt-3 ml-5 mr-5" app style="background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, 0.05); border-radius: 20px;" elevation="0" height="75">
            <v-img class="ml-7" src="https://d153chakadu90d.cloudfront.net/web/ProGPTs_logo.webp"
                @click="redirectToHome()" max-height="150" max-width="150" contain alt="ChatGPT ChatPro Logo AI"></v-img>
            <v-spacer></v-spacer>
            <v-btn rounded elevation="0" color="#7579e7" outlined class="" @click="goToRandom()"><v-icon left>mdi-creation</v-icon>Random GPT</v-btn>
            <a
                    href="#tally-open=3X4x1d&tally-layout=modal&tally-width=500&tally-emoji-animation=none&tally-auto-close=4000"><v-btn
                        rounded elevation="0" outlined class="ma-3">Subscribe<v-icon
                            right>mdi-email</v-icon></v-btn></a>
            <v-btn @click="goToSubmit()" rounded color="black" dark class="mr-10">
                Submit GPT<v-icon right>mdi-plus</v-icon>
            </v-btn>
        </v-app-bar>
        <!--MENU MOBILE-->
        <v-dialog v-model="dialogMenu" width="400" transition="dialog-top-transition">
            <v-card class="glass-card" elevation="10" outlined style="height: 400px; ">
                <center>
                    <p style="font-size: 25px; font-weight: 450; color: white;" class="mx-auto">Menu</p>
                    <v-divider color="white"></v-divider>
                </center>
                <v-btn @click="goToSubmit()" block depressed big color="black" class="mt-10" dark x-large rounded>
                    Submit GPT
                </v-btn>
                <a href="#tally-open=3X4x1d&tally-layout=modal&tally-width=500&tally-emoji-animation=none&tally-auto-close=4000"><v-btn block depressed big color="black" class="mt-10" dark x-large rounded style="text-decoration: none !important;">
                    <v-icon left>mdi-email</v-icon>Subscribe
                </v-btn></a>
                <v-btn @click="goToRandom()" block depressed big color="black" class="mt-10" dark x-large rounded>
                    <v-icon left>mdi-creation</v-icon>Random GPT
                </v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld";

export default {
    name: "App",
    data() {
        return {
            dialogMenu: false,
        }
    },

    methods: {

        goToSubmit() {
            window.location.href = "https://chatpro.pro/gpts"; //redirecciona a chatpro register
        },

        goToRandom() {
            this.$router.push("/random")
        },

    }
};
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

@media (min-width: 1040px) {
    .v-app-bar {
        margin-left: 30px !important;
    }
}

.glass-card {
    border-radius: 16px !important;
    border-color: white;
    padding: 20px;
    background-color: rgb(7, 0, 0);
}
</style>